import {useSelector} from "react-redux";
import Accordion from 'react-bootstrap/Accordion';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const AddressDebug = () => {
    const addressState = useSelector((state) => state.address);
    //console.log(addressState)

    function printAddressDetails(address) {
        const content = [];
        content.push(getTableRow("id", address.id));
        content.push(getTableRow("weight", address.weight));
        Object.keys(address.attrs).forEach((key, index) => {
            content.push(getTableRow(key, address.attrs[key]));
        });
        return content;
    }

    function getTableRow(label, content) {
        return <Row key={label}>
            <Col xs={3}>
                <strong>{label}</strong>
            </Col>
            <Col>
                {content}
            </Col>
        </Row>;
    }

    function printError(err) {
        if (err.hasError) return <p>{err.message} {err.detail} ({err.code})</p>;
        return <p>false</p>;
    }


    return <>
        <Row><Col xs={3}>Loading</Col><Col>{addressState.loading ? "true" : "false"}</Col></Row>
        <Row className={'mt-3'}><Col
            xs={3}>Selected</Col><Col>{addressState.selected ? addressState.selected.id : "none"}</Col></Row>
        <Row className={'mt-3'}><Col xs={3}>Error</Col><Col>{printError(addressState.error)}</Col></Row>
        <Row className={'mt-3'}><Col xs={3}>Loaded Address List:</Col><Col>
            <Accordion>
                {addressState.list.map(itm => {
                    return <Accordion.Item key={itm.id} eventKey={itm.id}>
                        <Accordion.Header>
                            {itm.attrs.label.replace('<b>', '').replace('</b>', '')}
                            {addressState.selected && itm.id === addressState.selected.id ?
                                <strong>&nbsp; (selected)</strong> : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                            {printAddressDetails(itm)}
                        </Accordion.Body>
                    </Accordion.Item>;
                })}
            </Accordion>
        </Col></Row>
    </>;
};