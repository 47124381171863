import React from 'react';
import {useDispatch} from "react-redux";
import Form from "react-bootstrap/Form";
import {dataEnableMeasureGroup, dataLoadFifthQuery} from "../../reducers/dataActions";
import {RxCross2, RxCrossCircled} from "react-icons/rx";
import Container from "react-bootstrap/Container";
import {InputGroup} from "react-bootstrap";
import {settingsSetClientEvent, settingsSetEventUID} from "../../reducers/settingsActions";

export const MeasureGroup = ({item}) => {
    const dispatch = useDispatch();

    if (!item) return <div className={'text-danger'}>item not set</div>;

    const handleSliderClicked = (event) => {
        const newValue = event.target.checked ? '1' : '0';
        setSelectionValue(newValue);
    };

    const handleForbiddenClicked = () => {
        //console.log("This is the forbidden element");
        if (selection === "-1") {
            setSelectionValue("0");
        } else {
            setSelectionValue("-1");
        }
    };

    const setSelectionValue = (newValue) => {
        dispatch(settingsSetEventUID({eventUID: item.parameter}))
        dispatch(settingsSetClientEvent({clientEvent: 'editMeasure'}))
        dispatch(dataEnableMeasureGroup({parameter: item.parameter, value: newValue}));
        dispatch(dataLoadFifthQuery());
    };

    const selection = item.subMeasure[0].selection;

    const isSliderActive = selection === "2" || selection === "1";

    const getGroupClass = (value) => {
        let groupClass;
        switch (value) {
            case "-1":
                groupClass = 'val0';
                break;
            case "0":
                groupClass = 'val1';
                break;
            case "1":
                groupClass = 'val2';
                break;
            case "2":
                groupClass = 'val3';
                break;
            default:
                groupClass = '';
                break;
        }
        return groupClass;
    };

    const getForbiddenButton = () => {
        return <div className={'d-flex justify-content-center cursor-pointer'}
                    style={{width: '25px'}}
                    onClick={handleForbiddenClicked}>
            {selection === "-1" ? <RxCrossCircled size={15}/> : <RxCross2 size={15}/>}
        </div>;
    };

    let myClass = getGroupClass(selection);

    return <>
        <div className={`measure-div ${myClass}`} id={`measure${item.parameter}`}>
            <div className={'label-row d-flex justify-content-center mt-2 mx-2'}
                 style={{'minWidth': '30px'}}>{item.displayText}</div>
            <Container className="d-flex justify-content-center align-items-center">
                <InputGroup className={'mb-2 d-flex justify-content-center'} style={{height: '18px'}}>
                    {getForbiddenButton()}
                    <Form.Check type="switch" id={`measureswitch${item.parameter}`}
                                className={'cursor-pointer'}
                                checked={isSliderActive}
                                onChange={handleSliderClicked}/>
                </InputGroup>
            </Container>
        </div>
    </>;
};