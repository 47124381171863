import {InputHeader} from "../layout/InputHeader";
import {InputContainer} from "../layout/InputContainer";
import React from "react";
import {useSelector} from "react-redux";
import {selectDataAttrs, selectDataEnergyAnalysis} from "../../reducers/dataSelectors";
import {useNavigate} from "react-router";
import { useEffect } from "react";
export const EnergyEfficiency = (props) => {

    const attrs = useSelector(selectDataAttrs);
    const energyAnalysis = useSelector(selectDataEnergyAnalysis);
    // MDS: If required data is not available, return null or a loading state
    const navigate = useNavigate();

    // Redirect immediately if critical data is missing
    useEffect(() => {
        if (!attrs || !attrs.energyReferenceArea || !energyAnalysis || !energyAnalysis.energyComparison) {
            navigate('/auswahl');
        }
    }, [attrs, energyAnalysis, navigate]);

    // Prevent rendering if the data is not ready
    if (!attrs || !attrs.energyReferenceArea || !energyAnalysis || !energyAnalysis.energyComparison) {
        return null; // Or a loading state if appropriate
    }

    
    const refArea = attrs?.energyReferenceArea;
    const geakEnergy = energyAnalysis?.energyComparison[0].geak.suppliedEndenergy[0];
    const proposedEnergy = energyAnalysis?.energyComparison[0].proposed.suppliedEndenergy[0];
    const improvedEnergy = energyAnalysis?.energyComparison[0].improved.suppliedEndenergy[0];

    
    

    const getEfficiencyRatio = (energy, refArea) => {
        const efficiencyValue = energy / refArea;
        const efficiencyCat = efficiencyValue / 50;
        return (efficiencyCat / 7) * 100;
    };

    let myEfficiencyRatio = getEfficiencyRatio(geakEnergy, refArea);
    let proposedRatio = getEfficiencyRatio(proposedEnergy, refArea);
    let improvedRatio = getEfficiencyRatio(improvedEnergy, refArea);

    if (myEfficiencyRatio > 100) myEfficiencyRatio = 99.5;
    if (proposedRatio > 100) proposedRatio = 99.5;
    if (improvedRatio > 100) improvedRatio = 99.5;

    //console.log('myRatio', myEfficiencyRatio, 'proposed', proposedRatio, 'improved', improvedRatio);
    //console.log('energy', geakEnergy, 'proposed', proposedEnergy, 'improved', improvedEnergy);

    // const efficiencyValue = geakEnergy / refArea;
    // const efficiencyCat = efficiencyValue / 50;
    // let efficiencyRatio = (efficiencyCat / 7) * 100;

    const swissAverage = 62.8571;
    const minergie = 21.4286;

    let chAvgLabel = energyAnalysis.comparisonText;
    let chAvgRating = swissAverage;
    if (chAvgLabel === "Minergie" || chAvgLabel === "Minergie-P") {
        chAvgRating = minergie;
    }

    const efficiencyClassGroupRatio = {
        "1": "7.1%",
        "2": "21.4%",
        "3": "35.7%",
        "4": "50.0%",
        "5": "64.29%",
        "6": "78.5%",
        "7": "92.8571%"
    };

    const myEfficiencyClass = efficiencyClassGroupRatio[energyAnalysis["efficiencyClass"]];

    return <InputContainer classes={'h-75 bg-opacity-10'} color={'bg-secondary'}>
        <InputHeader title={'Energieeffizienzklasse'}/>
        <div className="energieeffizienz">
            <div className="pic"></div>
            <div className="caption">
                <span>A</span>
                <span>B</span>
                <span>C</span>
                <span>D</span>
                <span>E</span>
                <span>F</span>
                <span>G</span>
            </div>
            <div className="chdurchschnitt" data-gui="chdurchschnitt" style={{left: `${chAvgRating}%`}}>
                <div className="ico"></div>
                <div className="txt" data-gui="chdurchschnitttext">{chAvgLabel}</div>
            </div>
            <div className="improved"
                 style={{left: `${improvedRatio}%`, display: Number(improvedEnergy) > 0 ? 'block' : 'none'}}>
                <div className="ico"></div>
                {/*<div className="txt" data-gui="improvedtext">Vorgeschlagene Massnahmen</div>*/}
                <div className="txtwrapper">
                    <div className="txt">Massnahmen</div>
                </div>
            </div>
            <div className="deinhaus"
                 style={{left: `${myEfficiencyRatio}%`}}>
                <div className="ico"></div>
                <div className="txtwrapper">
                    <div className="txt">Dein Haus</div>
                </div>
            </div>

            <div className="deineklasse"
                 data-gui="energyanalysis_efficiencyclass"
                 style={{left: myEfficiencyClass}}>
                <div className="ico"></div>
            </div>
        </div>
    </InputContainer>;

};