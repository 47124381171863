


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Home from "./components/page/Home";
import Header from "./components/layout/Header";
// import Footer from "./components/layout/Footer";
import {NotFound} from "./components/page/NotFound";
import Container from "react-bootstrap/Container";
import 'bootstrap/dist/css/bootstrap.min.css';
import store, {persistor} from './store';
import {Provider} from 'react-redux';
import {SelectAddress} from "./components/page/SelectAddress";
import {DataEntry} from "./components/page/DataEntry";
import {Debug} from "./components/devdebug/Debug";
import {Query} from "./components/devdebug/Query";
import {SituationAnalysis} from "./components/page/SituationAnalysis";
import {DataEntryDetails} from "./components/page/DataEntryDetails";
import {Measures} from "./components/page/Measures";
import {Incentives} from "./components/page/Incentives";
import {Financing} from "./components/page/Financing";
import {MeasurePlanning} from "./components/page/MeasurePlanning";
import {ConditionView} from "./components/devdebug/ConditionView";
import {Disclaimer} from "./components/page/Disclaimer";
import {TaxSavings} from "./components/page/TaxSavings";
import {PersistGate} from "redux-persist/integration/react";
import {Settings} from "./components/page/Settings";
import {Demo} from "./components/page/Demo";



const root = ReactDOM.createRoot(document.getElementById('root'));

const Layout = () => (
    <>
        <Container>
            <Header/>
            <Container>
                <Outlet/>
            </Container>
            {/*<Footer/>*/}
        </Container>
    </>
);

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Layout/>,
            errorElement: <NotFound/>,
            children: [
                {
                    path: '/',
                    element: <Home/>
                },
                {
                    path: '/auswahl',
                    element: <SelectAddress/>
                },
                {
                    path: '/dateneingabe',
                    element: <DataEntry/>
                },
                {
                    path: '/details',
                    element: <DataEntryDetails/>
                },
                {
                    path: "/situation",
                    element: <SituationAnalysis/>
                },
                {
                    path: "/massnahmen",
                    element: <Measures/>
                },
                {
                    path: "/massnahmenplanung",
                    element: <MeasurePlanning/>
                },
                {
                    path: "/fördergelder",
                    element: <Incentives/>
                },
                {
                    path: "/finanzplanung",
                    element: <Financing/>
                },
                {
                    path: "/steuerersparnis",
                    element: <TaxSavings/>
                },
                {
                    path: "/disclaimer",
                    element: <Disclaimer/>
                },
               {
                   path: '/debug',
                   element: <Debug/>
                },
                {
                   path: '/queries',
                   element: <Query/>
                },
                {
                   path: "/condition",
                   element: <ConditionView/>
                },
                {
                   path: "/settings",
                   element: <Settings/>
                },
                {
                    path: "/demo",
                    element: <Demo/>
                }
            ]
        }
    ]
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            
            <PersistGate loading={null} persistor={persistor}>
                <RouterProvider router={router} />
            </PersistGate>
            
            
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
